/* External dependencies */
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import Layout from '../../components/layout';
import Users from '../../components/users/Users';
import formatMessage from '../../intl/formatMessage';

export default function UsersPage() {
  const title = formatMessage('users');
  const { site: { siteMetadata: { titleTemplate }} } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <Users />
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
