/* External dependencies */
import { Col, Icon, Row, Text } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import { setLocalStorage } from '../common/helpers';
import Table from '../tables/Table';
import Search from '../search/Search';
import { User } from '../user-details/userTypes';
import { listUsers } from './actions';
import { UsersState } from './reducer';

export default function Users() {
  const { currentPage, loading, searchString, total, users } = useSelector(
    (state: RootState): UsersState => state.users,
  );
  const { currentUser } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listUsers());
  }, []);

  function onSearch(e) {
    dispatch(listUsers(e.target.value));
  }

  function resetInputField() {
    onSearch({
      target: {
        value: '',
      },
    });
  }

  function onPageChange(page) {
    dispatch(listUsers(searchString, page));
  }

  function onClickUser(user) {
    setLocalStorage('userId', user.id);

    navigate('/users/user-details');
  }

  function renderUserClickOnName(user) {
    return (
      <p style={{ cursor: 'pointer' }} onClick={() => onClickUser(user)}>
        {`${user.firstName} ${user.lastName}`}
      </p>
    );
  }

  const columns = [
    {
      // in order to firstName and lastName be clickable must be one of calls for the field
      field: 'firstName',
      title: formatMessage('firstName'),
    },
    {
      field: ({ role }) => formatMessage(role),
      title: formatMessage('userRole'),
    },
    {
      field: 'phone',
      title: formatMessage('phone'),
    },
    {
      field: 'status',
      title: formatMessage('userStatus'),
    },
  ];

  const topBar = (
    <Text
      textColor="table_cell_text_color"
      textSize={{ xs: 'mobileTitle', md: 'title' }}
      textWeight="700"
    >
      <FormattedMessage id="users" /> (
      {loading ? (
        <Icon cursor="pointer" m="0 .62rem" name="Loading3" size="20px" />
      ) : (
        total
      )}
      )
    </Text>
  );

  const controlBar = (
    <>
      <Row
        m={{ y: '.5rem', x: '-.5rem' }}
        justify="space-between"
        align={{ xs: 'flex-start', md: 'space-between' }}
      >
        <Col size={{ xs: '12', md: '6' }} style={{ marginBottom: '1rem' }}>
          <Search
            onChange={onSearch}
            onClear={resetInputField}
            placeholder={formatMessage('findUser')}
            value={searchString}
          />
        </Col>
        <Col
          size={{ xs: '12', md: '6' }}
          d={{ xs: 'block', md: 'flex' }}
          justify={{ md: 'flex-end' }}
          style={{ marginBottom: '1rem' }}
        >
          <PrimaryButton onClick={() => navigate('/users/create')} icon="Plus">
            {formatMessage('addUserBtn')}
          </PrimaryButton>
        </Col>
      </Row>
    </>
  );

  const filteredUsers = users
    .filter((user: User) => user && currentUser?.phone_number !== user.phone)
    .map((user: User) => {
      return { ...user, firstName: renderUserClickOnName(user) };
    });

  return (
    <Table
      columns={columns}
      controlBar={controlBar}
      currentPage={currentPage}
      className="table"
      emptyMessage={formatMessage('noUsers')}
      items={filteredUsers}
      loading={loading}
      onPageChange={onPageChange}
      searchString={searchString}
      stickyCol={0}
      topBar={topBar}
      totalItemsCount={total}
    />
  );
}
